import React, { useEffect, useState } from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";
import CardVideo from "../components/card-video";

const Cursos = props => {
  const [items, setItems] = useState([])
  const YOUTUBE_PLAYLIST_ITEMS_API = 'https://www.googleapis.com/youtube/v3/playlistItems'
  useEffect(() => {
    async function run() {
      const res = await fetch(`${YOUTUBE_PLAYLIST_ITEMS_API}?part=snippet&maxResults=50&playlistId=PLBr2v9Wg_tMuSIuezk7Ic49MgLJubGHdN&key=${process.env.GATSBY_YOUTUBE_API_KEY}`);
      const data = await res.json()
      setItems(data.items.reverse());
    }
    run()
  })
  
  return (
    <Layout location={props.location} lang="es">
      <SEO
        title="Cursos"
        keywords={[
          `blog`,
          `gatsby`,
          `javascript`,
          `react`,
          `victorsolaya`,
          `axazure`,
          `dynamics 365`,
          `dynamics crm`
        ]}
      />
      <div class="mt-12 max-w-lg mx-auto grid gap-5 lg:grid-cols-2 lg:max-w-none">
        {items.map(video => {
          const {id, snippet = {}} = video;
          const {title, thumbnails = {}, resourceId } = snippet;
          const {medium = {}} = thumbnails;
          const src = `https://www.youtube.com/embed/${resourceId.videoId}`
          return (
            <CardVideo key={id} title={title} src={src} />
          );
        })}
      </div>
    </Layout>
  );
};

export default Cursos;
