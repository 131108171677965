import { Link } from "gatsby";
import React from "react";

const CardVideo = props => {
  const { title, src } = props;
  return (
    <div className="flex flex-col rounded-lg shadow-lg overflow-hidden mx-auto py-2 px-4 bg-blue-marine w-full">
      <div className="text-center p-4">
        <h2 className="text-3xl tracking-tight  text-white sm:text-4xl">
          {title}
        </h2>
      </div>
      <iframe
        className="w-full h-64"
        src={src}
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen={true}
      ></iframe>
    </div>
  );
};

export default CardVideo;
